<template>
    <client-page>
        <sub-visual tit="ABOUT US" bg="/images/sub/visual/sv--about.jpg"></sub-visual>

        <section class="section--about1 pt-50 pt-lg-80 pb-60 pb-md-120 pb-lg-180">
            <v-container>
                <v-img src="/images/ci.svg" class="w-120px w-lg-180px mb-12 mb-lg-20" data-aos="fade-right"></v-img>
                <p class="page-text--lg line-height-15" data-aos="fade-left">
                    As a leading manufacturer of high-quality advertising solutions since its <br class="d-none d-md-block">
                    foundation in 1987, Minart Frame has grown through constant challenges and <br class="d-none d-md-block">
                    innovations by offering EZFLIP™ snap frames, EZFLIP™ LED back-lit advertising <br class="d-none d-md-block">
                    light panels, and other related products. Minart Frame develops, manufactures, <br class="d-none d-md-block">
                    supplies, and exports a wide range of advertising solutions.
                </p>
            </v-container>
        </section>

        <section class="section" style="background-image: url(/images/sub/about/s2-bg.jpg)">
            <v-container>
                <div class="tit-wrap text-center">
                    <h3 class="tit font-weight-bold line-height-1 color-white">
                         Our Vision
                    </h3>
                </div>
                <v-row class="word-keep-all">
                    <v-col cols="12" sm="6" md="3">
                        <card-primary borderColor="white" class="effect-none">
                            <div class="pa-20 pa-md-30 color-white">
                                <h5 class="tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num" data-aos="fade-down">
                                    01
                                </h5>
                                <p class="page-text line-height-15" data-aos="fade-up">
                                    As a constant challenger and innovator, we strive to design and manufacture the highest quality product.
                                </p>
                            </div>
                        </card-primary>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <card-primary borderColor="white" class="effect-none">
                            <div class="pa-20 pa-md-30 color-white">
                                <h5 class="tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num" data-aos="fade-down">
                                    02
                                </h5>
                                <p class="page-text line-height-15" data-aos="fade-up">
                                    In our user-experience-centered product design process, we aim to develop a high-quality product that actual users can use easily and safely.
                                </p>
                            </div>
                        </card-primary>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <card-primary borderColor="white" class="effect-none">
                            <div class="pa-20 pa-md-30 color-white">
                                <h5 class="tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num" data-aos="fade-down">
                                    03
                                </h5>
                                <p class="page-text line-height-15" data-aos="fade-up">
                                    In order to achieve our vision, we aim to engage customers and suppliers in long-term, mutually beneficial relationships.
                                </p>
                            </div>
                        </card-primary>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <card-primary borderColor="white" class="effect-none">
                            <div class="pa-20 pa-md-30 color-white">
                                <h5 class="tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num" data-aos="fade-down">
                                    04
                                </h5>
                                <p class="page-text line-height-15" data-aos="fade-up">
                                    Our goal is to meet the needs of our customers by providing the appropriate advices and high-quality products.
                                </p>
                            </div>
                        </card-primary>
                    </v-col>
                </v-row>
            </v-container>
        </section> 

        <section class="section secondary color-white">
            <v-container>
                <div class="tit-wrap text-center">
                    <h3 class="tit font-weight-bold">
                        Headquarters & Facilities
                    </h3>
                </div>
                <div class="rounded mb-20 mb-lg-40">
                    <iframe class="w-100 d-block" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3176.470045870956!2d126.8726446!3d37.23655370000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f7ab2b3ec59%3A0x40a3fb94d5273f9f!2sMinart!5e0!3m2!1sen!2skr!4v1675299636106!5m2!1sen!2skr" width="100%" height="516" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <v-row justify="center" no-gutters class="contact-details row-cols-2 row-cols-md-3 row-cols-lg-5">
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-location mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Location
                            </p>
                            <p class="page-text--sm color-grey-c">
                                <a target="_blank" href="https://www.google.com/maps/place/Minart/data=!3m1!4b1!4m6!3m5!1s0x357c9f7ab2b3ec59:0x40a3fb94d5273f9f!8m2!3d37.2365537!4d126.8726446!16s%2Fg%2F1tj94b_t?hl=en">
                                    103, Yangno-ro, Bibong-myeon, Hwaseong-si, Gyeonggi-do 18284 South Korea
                                </a>
                            </p>
                        </div>
                    </v-col>
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-mail mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Email
                            </p>
                            <p class="page-text--sm color-grey-c">
                                <a target="_blank" href="mailto:minart@minart.com">minart@minart.com</a>
                            </p>
                        </div>
                    </v-col>
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-home mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Website
                            </p>
                            <p class="page-text--sm color-grey-c">
                                <router-link to="/">minart.com</router-link>
                            </p>
                        </div>
                    </v-col>
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-tel mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Tel
                            </p>
                            <p class="page-text--sm color-grey-c">
                                <a target="_blank" href="tel:+82-31-465-9088">+82-31-465-9088</a>
                            </p>
                        </div>
                    </v-col>
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-fax mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Fax
                            </p>
                            <p class="page-text--sm color-grey-c">
                                +82-31-465-9098
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import SubVisual from "@/components/client/en/sub/sub-visual.vue";
import CardPrimary from "@/components/dumb/card-primary.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        CardPrimary,
    },
};
</script>

<style scoped>
.section--about1{
    position: relative;
    overflow: hidden;
}
.section--about1::before{
    content: "Introduction";
    display: block;
    position: absolute;
    line-height: 1;
    color: #f5f5f5;
    font-size: 5rem;
    right: -4px;
    bottom: -8px;
    font-weight: 600;
    z-index: 0;
}
.num{
    width: fit-content;
    border-bottom: 2px solid #fff;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .section--about1::before{
        font-size: 12rem;
        right: -10px;
        bottom: -18px;
    }
}
@media (min-width:1024px){
    .section--about1::before{
        font-size: 16rem;
        right: -14px;
        bottom: -24px;
    }
    .contact-details .contact-detail:not(:last-child){
        border-right: 1px solid rgba(255, 255, 255, 0.3);
    }
}
@media (min-width:1300px){
    .section--about1::before{
        font-size: 20rem;
        right: -14px;
        bottom: -30px;
    }
}

</style>
