var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": "ABOUT US",
      "bg": "/images/sub/visual/sv--about.jpg"
    }
  }), _c('section', {
    staticClass: "section--about1 pt-50 pt-lg-80 pb-60 pb-md-120 pb-lg-180"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "w-120px w-lg-180px mb-12 mb-lg-20",
    attrs: {
      "src": "/images/ci.svg",
      "data-aos": "fade-right"
    }
  }), _c('p', {
    staticClass: "page-text--lg line-height-15",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_vm._v(" As a leading manufacturer of high-quality advertising solutions since its "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" foundation in 1987, Minart Frame has grown through constant challenges and "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" innovations by offering EZFLIP™ snap frames, EZFLIP™ LED back-lit advertising "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" light panels, and other related products. Minart Frame develops, manufactures, "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" supplies, and exports a wide range of advertising solutions. ")])], 1)], 1), _c('section', {
    staticClass: "section",
    staticStyle: {
      "background-image": "url(/images/sub/about/s2-bg.jpg)"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold line-height-1 color-white"
  }, [_vm._v(" Our Vision ")])]), _c('v-row', {
    staticClass: "word-keep-all"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "effect-none",
    attrs: {
      "borderColor": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 color-white"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num",
    attrs: {
      "data-aos": "fade-down"
    }
  }, [_vm._v(" 01 ")]), _c('p', {
    staticClass: "page-text line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" As a constant challenger and innovator, we strive to design and manufacture the highest quality product. ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "effect-none",
    attrs: {
      "borderColor": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 color-white"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num",
    attrs: {
      "data-aos": "fade-down"
    }
  }, [_vm._v(" 02 ")]), _c('p', {
    staticClass: "page-text line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" In our user-experience-centered product design process, we aim to develop a high-quality product that actual users can use easily and safely. ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "effect-none",
    attrs: {
      "borderColor": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 color-white"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num",
    attrs: {
      "data-aos": "fade-down"
    }
  }, [_vm._v(" 03 ")]), _c('p', {
    staticClass: "page-text line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" In order to achieve our vision, we aim to engage customers and suppliers in long-term, mutually beneficial relationships. ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "effect-none",
    attrs: {
      "borderColor": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 color-white"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num",
    attrs: {
      "data-aos": "fade-down"
    }
  }, [_vm._v(" 04 ")]), _c('p', {
    staticClass: "page-text line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" Our goal is to meet the needs of our customers by providing the appropriate advices and high-quality products. ")])])])], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "section secondary color-white"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v(" Headquarters & Facilities ")])]), _c('div', {
    staticClass: "rounded mb-20 mb-lg-40"
  }, [_c('iframe', {
    staticClass: "w-100 d-block",
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3176.470045870956!2d126.8726446!3d37.23655370000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f7ab2b3ec59%3A0x40a3fb94d5273f9f!2sMinart!5e0!3m2!1sen!2skr!4v1675299636106!5m2!1sen!2skr",
      "width": "100%",
      "height": "516",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })]), _c('v-row', {
    staticClass: "contact-details row-cols-2 row-cols-md-3 row-cols-lg-5",
    attrs: {
      "justify": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-location mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Location ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.google.com/maps/place/Minart/data=!3m1!4b1!4m6!3m5!1s0x357c9f7ab2b3ec59:0x40a3fb94d5273f9f!8m2!3d37.2365537!4d126.8726446!16s%2Fg%2F1tj94b_t?hl=en"
    }
  }, [_vm._v(" 103, Yangno-ro, Bibong-myeon, Hwaseong-si, Gyeonggi-do 18284 South Korea ")])])])]), _c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-mail mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Email ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "mailto:minart@minart.com"
    }
  }, [_vm._v("minart@minart.com")])])])]), _c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-home mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Website ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("minart.com")])], 1)])]), _c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-tel mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Tel ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "tel:+82-31-465-9088"
    }
  }, [_vm._v("+82-31-465-9088")])])])]), _c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-fax mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Fax ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_vm._v(" +82-31-465-9098 ")])])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }